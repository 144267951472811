import { useCallback, useMemo } from 'react';
import { capitalize } from 'lodash';
import Axios from 'axios';
import { useReceivePayload, useCreateEntity } from '@/lib/entities';
import { ax } from '@/lib/api';
// import { getThumbnails } from 'video-metadata-thumbnails';
import { useRequestReducer } from './api';

export const useCreateMedium = ({ type }) => {
  const receivePayload = useReceivePayload();
  const createEntity = useCreateEntity();
  const mediumType = capitalize(type);
  const [state, dispatch] = useRequestReducer();
  const req = useCallback(
    async ({ file, attributes: a = {} }) => {
      const attributes = {
        type: mediumType,
        filename: file.name,
        ...a,
        // 'owner-id': clipId,
        // 'owner-type': 'CelebrationClip',
      };

      dispatch({ type: 'start' });
      const res = await ax().post(`/v3/media`, {
        data: {
          type: mediumType,
          attributes,
        },
      });
      const { data } = res.data;
      const tmpURL = URL.createObjectURL(file);
      createEntity({ id: data.id, type: data.type, tmpURL });
      // res.data.data.attributes.blob = file;
      // add clip thumbnail
      // if (type === 'video') {
      //   const [thumbnail] = await getThumbnails(file, { start: 0, end: 0 });
      //   res.data.data.attributes.tmpThumbnailURL = URL.createObjectURL(thumbnail.blob);
      // } else {
      //   res.data.data.attributes.tmpThumbnailURL = URL.createObjectURL(file);
      // }

      receivePayload(res);
      const { 'upload-url': uploadURL } = res.data.data.attributes;
      await Axios.put(uploadURL, file, {
        headers: { 'Content-Type': file.type },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            dispatch({ type: 'progress', progress });
          }
        },
      });
      dispatch({ type: 'success', data: res });
      return res;
    },
    [mediumType, receivePayload]
  );
  return useMemo(() => [req, state], [req, state]);
};

// TODO: broken wip for uploading mp4 link as video
export const useCreateVideoMediumFromURL = ({ type = 'video' }) => {
  const receivePayload = useReceivePayload();
  const createEntity = useCreateEntity();
  const mediumType = capitalize(type);
  const [state, dispatch] = useRequestReducer();
  const req = useCallback(
    async ({ url, attributes: a = {} }) => {
      const attributes = {
        type: mediumType,
        filename: '',
        ...a,
        // filename: filename
        // 'owner-id': clipId,
        // 'owner-type': 'CelebrationClip',
      };

      dispatch({ type: 'start' });
      const res = await ax().post(`/v3/media`, {
        data: {
          type: mediumType,
          attributes,
        },
      });
      const { data } = res.data;
      createEntity({ id: data.id, type: data.type, tmpURL: url });
      // res.data.data.attributes.blob = file;
      // add clip thumbnail
      // if (type === 'video') {
      //   const [thumbnail] = await getThumbnails(file, { start: 0, end: 0 });
      //   res.data.data.attributes.tmpThumbnailURL = URL.createObjectURL(thumbnail.blob);
      // } else {
      //   res.data.data.attributes.tmpThumbnailURL = URL.createObjectURL(file);
      // }

      receivePayload(res);
      // const { 'upload-url': uploadURL } = res.data.data.attributes;
      // await Axios.put(uploadURL, file, {
      //   headers: { 'Content-Type': file.type },
      //   onUploadProgress: (progressEvent) => {
      //     const totalLength = progressEvent.lengthComputable
      //       ? progressEvent.total
      //       : progressEvent.target.getResponseHeader('content-length') ||
      //         progressEvent.target.getResponseHeader('x-decompressed-content-length');
      //     if (totalLength !== null) {
      //       const progress = Math.round((progressEvent.loaded * 100) / totalLength);
      //       dispatch({ type: 'progress', progress });
      //     }
      //   },
      // });
      dispatch({ type: 'success', data: res });
      return res;
    },
    [mediumType, receivePayload]
  );
  return useMemo(() => [req, state], [req, state]);
};

export default useCreateMedium;
