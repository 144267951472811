import React, { createContext, useMemo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';
import { original } from 'immer';
import normalize from 'jsonapi-normalizer';

export const EntityCacheContext = createContext({});

/* eslint-disable no-param-reassign */
function EntityCache({ children, initialPayload }) {
  let initialEntities = {};
  if (initialPayload) {
    const normalized = normalize(initialPayload.data);
    initialEntities = normalized.entities;
  }
  const [entities, updateEntities] = useImmer(initialEntities);

  const entitiesVvalue = useMemo(() => {
    const clearEntities = () => updateEntities(() => initialEntities);
    const receivePayload = (payload, url) => {
      const normalized = normalize(payload);

      updateEntities((draft) => {
        Object.keys(normalized.entities).forEach((entityType) => {
          if (!draft[entityType]) {
            draft[entityType] = {};
          }
          Object.keys(normalized.entities[entityType]).forEach((id) => {
            // if we have an entity in state, only replace changed props
            if (draft[entityType][id]) {
              Object.keys(normalized.entities[entityType][id]).forEach((prop) => {
                const prevArrays = original(draft)[entityType][id][prop];
                if (
                  (prop === 'video-clips' || prop === 'image-clips' || prop === 'visible-clips') &&
                  prevArrays &&
                  entityType === 'celebrations' &&
                  url.includes('page=')
                ) {
                  draft[entityType][id][prop] = [
                    ...prevArrays,
                    ...normalized.entities[entityType][id][prop],
                  ].filter(
                    (value, index, self) => index === self.findIndex((t) => t.id === value.id)
                  );
                } else draft[entityType][id][prop] = normalized.entities[entityType][id][prop];
              });
            }
            // if no entity in state, create the new entity
            if (!draft[entityType][id]) {
              draft[entityType][id] = normalized.entities[entityType][id];
            }
          });
        });
      });
    };

    return { entities, updateEntities, receivePayload, clearEntities };
  }, [entities, updateEntities, initialEntities]);

  return (
    <EntityCacheContext.Provider value={entitiesVvalue}>{children}</EntityCacheContext.Provider>
  );
}
EntityCache.propTypes = {
  children: PropTypes.node.isRequired,
  initialPayload: PropTypes.shape({}),
};
EntityCache.defaultProps = { initialPayload: null };

export const useReceivePayload = () => useContext(EntityCacheContext).receivePayload;
export const useEntityCache = (fn) => {
  const { entities } = useContext(EntityCacheContext);
  return fn(entities);
};

export const useEntities = ({ type, fallback = undefined }) => {
  const { entities } = useContext(EntityCacheContext);
  if (!type) return fallback;
  return entities?.[type];
};

export const useEntity = ({ type = null, id, fallback } = {}) => {
  const entities = useEntities({ type });
  if (!entities) return fallback || undefined;
  return entities[id] || fallback;
};

export const useRelatedEntities = ({ type, id, relationship }) => {
  const getRelatedEntities = useCallback(
    (entities) => {
      // get entity
      const byType = entities[type];
      if (!byType) return undefined;
      const entity = byType[id];
      if (!entity) return undefined;
      return entity[relationship]?.map(
        (relatedEntity) => entities[relatedEntity.type]?.[relatedEntity.id]
      );
    },
    [id, relationship, type]
  );
  return useEntityCache(getRelatedEntities);
};

export const useCreateEntity = ({ type } = {}) => {
  const { updateEntities } = useContext(EntityCacheContext);
  return useCallback(
    (params = {}) => {
      updateEntities((draft) => {
        const t = type || params.type;
        if (draft[t]) {
          draft[t][params.id] = params;
        }
        if (!draft[t]) {
          draft[t] = { [params.id]: params };
        }
      });
    },
    [type, updateEntities]
  );
};

export const useUpdateEntity = ({ type, id }) => {
  const { updateEntities } = useContext(EntityCacheContext);
  return useCallback(
    (params = {}) => {
      updateEntities((draft) => {
        const entity = draft[type][id];
        if (entity) {
          Object.keys(params).forEach((key) => {
            entity[key] = params[key];
          });
        }
      });
    },
    [type, id, updateEntities]
  );
};

export const useRemoveEntity = (props) => {
  const { updateEntities } = useContext(EntityCacheContext);
  const type = props?.type;
  const id = props?.id;
  return useCallback(() => {
    if (type && id) {
      updateEntities((draft) => {
        delete draft[type][id];
      });
    }
  }, [type, id, updateEntities]);
};

export const useRemoveEntityReference = ({ type, id, reference }) => {
  const { updateEntities } = useContext(EntityCacheContext);
  return useCallback(() => {
    updateEntities((draft) => {
      const entity = draft[type][id];
      const referenceIndex = entity[reference.type].findIndex((ref) => ref.id === reference.id);
      entity[reference.type].splice(referenceIndex, 1);
    });
  }, [type, id, updateEntities]);
};

export const useClearEntityCache = () => {
  const { clearEntities } = useContext(EntityCacheContext);
  return clearEntities;
};

export const useCreateEntityReference = ({ type, id }) => {
  const { updateEntities } = useContext(EntityCacheContext);
  return useCallback(
    (params) => {
      updateEntities((draft) => {
        const entity = draft[type][id];
        if (!entity[params.type]) {
          entity[params.type] = [];
        }
        entity[params.type].push(params);
      });
    },
    [type, id, updateEntities]
  );
};

export const entityReferenceShape = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
});

export const useRelatedEntity = ({ id, type, relation }) => {
  const entity = useEntity({ id, type });
  return useEntity(entity ? entity[relation] : {});
};

export const jsonAPIEntityParamsProps = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
});

export default EntityCache;
