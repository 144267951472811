import React from 'react';
import { Button, Box, Text, Heading, Flex } from 'rebass';
import { FiX } from 'react-icons/fi';

const bannerSizes = {
  xsmall: {
    containerPadding: 1,
    iconSize: 36,
    headingSize: 2,
    textSize: 1,
    gap: 2,
  },
  small: {
    containerPadding: 3,
    iconSize: 36,
    headingSize: 2,
    textSize: 1,
    gap: 3,
  },
  default: {
    containerPadding: 5,
    iconSize: 48,
    headingSize: 3,
    textSize: 2,
    gap: 5,
  },
};

const getSized = (size, property) =>
  bannerSizes[size] ? bannerSizes[size][property] : bannerSizes.default[property];

function Banner({ icon, bg, color, heading, text, size, subText, onClose, ...props }) {
  return (
    <Flex bg={bg} p={getSized(size, 'containerPadding')} sx={{ borderRadius: 'medium' }} {...props}>
      <Flex alignItems="center">
        {icon && <Text as={icon} size={getSized(size, 'iconSize')} color={color} />}
        <Box pl={getSized(size, 'gap')}>
          <Heading fontSize={getSized(size, 'headingSize')} color={color}>
            {heading}
          </Heading>
          <Text fontSize={getSized(size, 'textSize')} color={color} fontWeight={400}>
            {text}
          </Text>
          {subText && (
            <Text fontSize="16px" color={color} mt={1}>
              {subText}
            </Text>
          )}
        </Box>
      </Flex>
      {!!onClose && (
        <Button type="button" variant="transparent" onClick={onClose} ml="auto">
          <Text as={FiX} fontSize={getSized(size, 'textSize')} color={color} />
        </Button>
      )}
    </Flex>
  );
}
Banner.defaultProps = {
  size: 'default',
  onClose: null,
};

export default Banner;
