import React, { useState, useEffect } from 'react';
import UAParser from 'ua-parser-js';
import { Flex, Text, Button } from 'rebass';
import { AiOutlineWarning } from 'react-icons/ai';
import { FaChrome, FaEdge, FaSafari, FaFirefox } from 'react-icons/fa';
import store from 'store';
import AnimatedDialog from '@/components/app/AnimatedDialog';

function BrowserButton({ icon, text, href }) {
  return (
    <Button
      mr={2}
      mb={[2, 0]}
      sx={{ display: 'flex', alignItems: 'center' }}
      onClick={() => window.open(href, '_blank')}
    >
      <Text size={24} mr={1} as={icon} />
      {text}
    </Button>
  );
}

function BrowserWarning() {
  const [isOpen, setOpen] = useState(false);

  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  const osName = parser.getResult().os.name;

  const isMobileUser = ['iOS', 'Android'].includes(osName) || parser.getDevice().model === 'iPad';

  useEffect(() => {
    const isBrowserWarningEnabled = store.get('browserWarning');
    const isMobile = isMobileUser;
    const isChrome = browserName.toLowerCase().includes('chrome');
    const isSafari = browserName.toLowerCase().includes('safari');
    const isFirefox = browserName.toLowerCase().includes('firefox');
    const isEdge = browserName.toLowerCase().includes('edge');

    const shouldOpenWarning =
      (isMobile && !isChrome && !isSafari) ||
      (!isMobile && !isChrome && !isSafari && !isFirefox && !isEdge);

    if (!isBrowserWarningEnabled && shouldOpenWarning) {
      setOpen(false);
    }
  }, [isMobileUser, osName, browserName]);

  return (
    <AnimatedDialog
      isOpen={isOpen}
      contentProps={{ width: '100vw', maxWidth: '700px' }}
      onDismiss={() => {
        setOpen(false);
        store.set('browserWarning', true);
      }}
    >
      <Flex pb={4} px={2} alignItems="center" width="100%" flexDirection="column">
        <Text size={36} mb={2} as={AiOutlineWarning} />
        <Flex justifyContent="center" alignItems="center" flexDirection="column">
          <Text textAlign="center">
            We&apos;re afraid your browser may not fully support some of the advanced features we
            use in Celebrate.buzz
            <br /> You&apos;ll get the best experience using one of the browsers listed below
          </Text>
          <Flex mt={5} flexDirection={['column', 'row']}>
            <BrowserButton icon={FaChrome} text="Chrome" href="https://www.google.com/chrome/" />
            {!isMobileUser && (
              <BrowserButton
                icon={FaFirefox}
                text="Firefox"
                href="https://www.mozilla.org/en-CA/firefox/new/"
              />
            )}
            <BrowserButton
              icon={FaSafari}
              text="Safari"
              href="https://support.apple.com/en_GB/downloads/safari"
            />
            {!isMobileUser && (
              <BrowserButton
                icon={FaEdge}
                text="Edge"
                href="https://www.microsoft.com/en-us/edge"
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </AnimatedDialog>
  );
}

export default BrowserWarning;
