import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
  useReducer,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import { snakeCase } from 'lodash';
import UAParser from 'ua-parser-js';
import Axios from 'axios';
import useSWR, { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { ax, basicHeaders, postPrivateEventDetails } from '@/lib/api';
import { useClearEntityCache } from '@/lib/entities';
import { clearCelebrationId, sendTrackingEvent } from '@/lib/helper';
import useSendToGTM from '@/hooks/googleTagManager';

const baseURL = process.env.NEXT_PUBLIC_API_BASE;

export const AuthContext = createContext({});

const useHandlePostLogin = () => {
  const { push } = useRouter();
  const [{ redirect }, dispatch] = useContext(AuthContext);
  return async () => {
    if (redirect) {
      push(redirect);
      dispatch({ type: 'setPostLoginRedirect', redirect: null });
    }
  };
};

const AuthMember = ({ children }) => {
  const [data, setData] = useState(null);
  const handlePostLogin = useHandlePostLogin();
  const { push, query } = useRouter();
  const clearCache = useClearEntityCache();
  const queryParam = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  const [{ token, memberId }, dispatch] = useContext(AuthContext);
  const { mutate } = useSWRConfig();
  const sendToGTM = useSendToGTM();

  async function getData() {
    const response = await ax({ updatedToken: token }).get(`/v3/me`);
    if (response.status === 200) {
      setData(response.data?.data);
      mutate(`/v2/members/${response.data?.data?.id}/identifiers`);
    } else if (response.status === 401) {
      store.remove('token');
      store.remove('selectedDeviceID');
      store.remove('selectedAudioDeviceID');
      dispatch({ type: 'logout' });
      clearCache();
      push('/sign-in');
    }
  }

  useEffect(() => {
    if (token) {
      getData();
    }
  }, [token]);

  useEffect(() => {
    if (data && !memberId) {
      const { id } = data;
      dispatch({ type: 'setMember', id });

      handlePostLogin();
    }
  }, [data, memberId, dispatch, handlePostLogin]);

  useEffect(() => {
    if (memberId) {
      postPrivateEventDetails(`member_returned${queryParam ? `&${queryParam}` : ''}`, {
        'subject-type': 'Member',
        'subject-id': memberId,
      });
      const gtmObject = {
        event: 'page_view',
        User_Id: memberId,
      };
      sendToGTM(gtmObject);
    }
  }, [memberId]);

  return children;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'logout':
      return { memberId: null, token: null, isAuthenticated: false, showWelcomePage: true };
    case 'setToken':
      return { ...state, token: action.token, isAuthenticated: true };
    case 'setMember':
      return { ...state, memberId: action.id };
    case 'setPostLoginRedirect':
      return { ...state, redirect: action.redirect };
    case 'setShowWelcomePage':
      return { ...state, showWelcomePage: action.showWelcomePage };
    case 'setDownloadLimitation':
      return { ...state, downloadLimitation: action.downloadLimitation };
    default:
      return state;
  }
};

function Auth({ children }) {
  const [authState, dispatch] = useReducer(reducer, {
    isAuthenticated: !!store.get('token'),
    memberId: null,
    token: store.get('token'),
    redirect: null,
    showWelcomePage: true,
    downloadLimitation: 0,
  });

  const handleClick = (event) => {
    const { target } = event;

    if (target.tagName === 'A' && authState?.token) {
      const title = target.getAttribute('title') || target.innerText;
      const href = target.getAttribute('title') || target.href;

      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: title,
          url: href,
        },
      });
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const AuthContextValue = useMemo(() => [authState, dispatch], [authState, dispatch]);
  const parser = new UAParser();
  const osName = parser.getResult().os.name;

  const isMobileUser = ['iOS', 'Android'].includes(osName) || parser.getDevice().model === 'iPad';

  /* eslint-disable no-return-assign, no-nested-ternary, no-cond-assign, no-bitwise, no-new, no-param-reassign, no-inner-declarations */
  useEffect(() => {
    // this is to check what the maximum size video user can download.
    if (!(isMobileUser || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 5))) {
      function findFirstPositive(
        f,
        b = 1n,
        d = (e, g, c) =>
          g < e
            ? -1
            : f((c = (e + g) >> 1n)) > 0
            ? c === e || f(c - 1n) <= 0
              ? c
              : d(e, c - 1n)
            : d(c + 1n, g)
      ) {
        for (; f(b) <= 0; b <<= 1n);
        return d(b >> 1n, b) - 1n;
      }

      const tries = [];
      const maxSize = findFirstPositive((x) => {
        tries.push(Number(x).toLocaleString());
        try {
          new ArrayBuffer(Number(x));
          return false;
        } catch {
          return true;
        }
      });
      dispatch({ type: 'setDownloadLimitation', downloadLimitation: Number(maxSize) });
    } else {
      dispatch({ type: 'setDownloadLimitation', downloadLimitation: 1000000000 });
    }
  }, [isMobileUser]);

  return (
    <AuthContext.Provider value={AuthContextValue}>
      <AuthMember>{children}</AuthMember>
    </AuthContext.Provider>
  );
}
Auth.propTypes = { children: PropTypes.node.isRequired };

export const useAuth = () => {
  const [state] = useContext(AuthContext);
  const auth = useMemo(() => ({ ...state, isAuthenticated: !!store.get('token') }), [state]);
  return auth;
};

export const useFetchAuthToken = () => {
  const [, dispatch] = useContext(AuthContext);
  return useCallback(
    async ({ id, secret }) => {
      try {
        const tokenResponse = await Axios.post(
          `${baseURL}/v3/auth_tokens`,
          { restore_token_id: id },
          { headers: { ...basicHeaders, token_secret: secret } }
        );
        const { 'auth-token': token } = tokenResponse.data.data.attributes;
        store.remove('token');
        localStorage.removeItem('token');
        store.set('token', token.replace(/"/g, ''));
        dispatch({ type: 'setToken', token: token.replace(/"/g, '') });

        return { token };
      } catch (err) {
        return err;
      }
    },
    [dispatch]
  );
};

export const useSetPostLoginRedirect = () => {
  const [, dispatch] = useContext(AuthContext);
  return useCallback(
    (path) => {
      dispatch({ type: 'setPostLoginRedirect', redirect: path });
    },
    [dispatch]
  );
};

export const useLogin = () => {
  const fetchAuthToken = useFetchAuthToken();
  const { redirect } = useAuth();
  const setRedirect = useSetPostLoginRedirect();

  return async (attributes, posterId, redirectURL) => {
    // we are logging in, and no authenticated layout component has set a redirect
    // member component will handle redirect when member is in state
    if (!redirect) {
      if (redirectURL) setRedirect(redirectURL);
      else if (posterId) {
        setRedirect(`/dashboard/${posterId}/poster?isFromNew=true`);
      } else setRedirect('/');
    }
    const loginResponse = await ax({ authenticated: posterId || false }).post(
      `/v3/login`,
      attributes
    );
    const { id, secret } = loginResponse.data.data.attributes;
    await fetchAuthToken({ id, secret });
  };
};

export const useCreateAccount = () => {
  const { push } = useRouter();
  const fetchAuthToken = useFetchAuthToken();
  const { redirect } = useAuth();
  const { mutate } = useSWR('/v3/me');

  return async (values, posterId, redirectURL) => {
    // we are logging in, and no authenticated layout component has set a redirect
    // member component will handle redirect when member is in state
    if (!redirect) {
      // setRedirect('/new');
      clearCelebrationId();
    }
    const formattedValues = Object.keys(values).reduce((acc, el) => {
      const formattedKey = snakeCase(el);
      return { ...acc, [formattedKey]: values[el] };
    }, {});

    if (store.get('iufi')) formattedValues.source = 'impact';

    const accountRes = await ax({ authenticated: posterId || false }).post(
      '/v3/accounts',
      formattedValues
    );

    if (!posterId) {
      const { id, secret } = accountRes.data.data.attributes;
      await fetchAuthToken({ id, secret });
      mutate();
      const url = '/?account-created=true';
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Create Account',
          url,
        },
      });
      push(url);
    } else if (redirectURL) {
      sendTrackingEvent(`member_visit`, {
        context: {
          url_text: 'Create Account',
          url: redirectURL,
        },
      });
      push(redirectURL);
    }
  };
};

export const useCreateNewAccount = () => {
  const fetchAuthToken = useFetchAuthToken();

  return useCallback(
    async (attributes = undefined) => {
      let res = null;
      if (attributes) res = await ax().post('/v3/celebration_members', { data: { attributes } });
      else res = await ax().post('/v3/celebration_members');

      if (res.status === 200 || res.status === 201) {
        const {
          data: {
            data: {
              attributes: { id: memId, secret },
            },
          },
        } = res;
        const { token } = await fetchAuthToken({ id: memId, secret });
        return token;
      }
      return null;
    },
    [fetchAuthToken]
  );
};

export default Auth;
