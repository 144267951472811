import { useEffect, useRef } from 'react';
import UAParser from 'ua-parser-js';
import { useEntities } from '@/lib/entities';

// temporary until we have a user model
export const useUserCelebrations = () => {
  const celebrations = useEntities({ type: 'celebrations' });
  return Object.keys(celebrations);
};

export const useIsSafariUser = () => {
  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  return browserName?.includes('Safari');
};

export const useIsFireFoxUser = () => {
  const parser = new UAParser();
  const browserName = parser.getResult().browser.name;
  return browserName.toLowerCase()?.includes('firefox');
};

export const useVisibilityChange = (onVisibilityChange) => {
  const lastVisibilityChangeTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleVisibilityChange = () => {
      const now = Date.now();
      if (document.hidden) {
        lastVisibilityChangeTimeRef.current = now;
      } else {
        const timeAway = now - lastVisibilityChangeTimeRef.current;

        onVisibilityChange(timeAway);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onVisibilityChange]);
};
