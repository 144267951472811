import React from 'react';
import { FiXOctagon } from 'react-icons/fi';
import Banner from '@/components/app/Banner';

function ErrorBanner({ text, heading, size, ...props }) {
  return (
    <Banner
      icon={FiXOctagon}
      color="pinks.5"
      bg="pinks.1"
      heading={heading}
      text={text}
      size={size}
      {...props}
    />
  );
}

export default ErrorBanner;
