import { useMemo, useCallback, useState, createRef, useContext } from 'react';
import { get, uniqBy } from 'lodash';
import { getMetadata } from 'video-metadata-thumbnails';
import { useRouter } from 'next/router';
import { original } from 'immer';
import UAParser from 'ua-parser-js';
import { saveAs } from 'file-saver';
import store from 'store';
import { EntityCacheContext, useEntity, useReceivePayload } from '@/lib/entities';
import { useRequestReducer } from '@/hooks/api';
import { ax } from '@/lib/api';
import useImage from './image';
// eslint-disable-next-line import/no-cycle
import { useClipEntities } from './celebration';
import { useCreateMedium } from './medium';
import { useMemberName } from './member';

const getDuration = (clip = {}) => {
  const { 'trim-start': trimStartAt, 'trim-end': trimEndAt, 'image-duration': duration } = clip;

  if (duration) return duration;
  if (!trimEndAt) return 5;
  return trimEndAt - trimStartAt;
};

export const useTimelineClips = (clips) => {
  const videoableClips = useClipEntities();
  return useMemo(() => {
    return uniqBy(
      clips.reduce((acc, el) => {
        const clip = videoableClips[el.id];
        if (acc.length === 0) {
          const endAt = getDuration(clip);
          return [{ startAt: 0, endAt, position: 0, ...clip }];
        }

        const [{ endAt: lastEndAt }] = acc.slice(-1);
        const endAt = lastEndAt + getDuration(clip);

        return [...acc, { startAt: lastEndAt, endAt, ...clip }];
      }, []),
      'id'
    );
  }, [clips, videoableClips]);
};

export const useVideoableClip = (id) => {
  const clips = useClipEntities();
  return clips[id];
};
export const useClip = ({ id }) => {
  const videoableClip = useVideoableClip(id) || {};
  const celebrationClip = useEntity({ id, type: 'celebration-clips' }) || {};
  return { ...celebrationClip, ...videoableClip };
};

export const useClipTextClip = ({ id }) => {
  const clip = useVideoableClip(id);
  let textClipParams = [];
  if (clip?.['text-clips']) textClipParams = get(clip, "['text-clips']") || [];
  return textClipParams.map((cli) => useEntity(cli));
};

export const useCanvasImageClip = ({ id }) => {
  const clip = useVideoableClip(id);
  const { 'canvas-image': canvasImage } = clip;
  const image = useEntity(canvasImage);
  return image;
};

export const useClipStickerClip = ({ id }) => {
  const clip = useVideoableClip(id);

  if (!clip || !clip?.['sticker-clips']) return [];

  const { 'sticker-clips': clips } = clip;
  return !clips
    ? []
    : clips.map((cli) => {
        const stickerClip = useEntity(cli);
        if (stickerClip) {
          const imageClip = useImage(stickerClip?.image);
          const {
            width: stickerWidth,
            height: stickerHeight,
            x,
            y,
            id: stickerClipId,
          } = stickerClip;

          return {
            id: stickerClipId,
            width: stickerWidth,
            height: stickerHeight,
            x,
            y,
            src: imageClip,
            resetButtonRef: createRef(),
          };
        }
        return {
          resetButtonRef: createRef(),
        };
      });
};

export const useCheckIsIosUser = () => {
  const parser = new UAParser();
  const osName = parser.getResult().os.name;
  return ['iOS'].includes(osName);
};

export const useAddClipToEntities = () => {
  const { updateEntities } = useContext(EntityCacheContext);

  const fn = useCallback(
    async (type, id, celebrationId, attributes, updateMainClipsOnly = false) => {
      try {
        /* eslint no-param-reassign: "error" */
        updateEntities((draft) => {
          if (attributes && draft[type] && draft[type][id]) {
            draft[type][id] = { ...attributes };
          }
          if (!updateMainClipsOnly && draft.celebrations[celebrationId]) {
            const currentType = type.slice(type.indexOf('-') + 1);
            const prevArrays = original(draft).celebrations[celebrationId][currentType] || [];
            draft.celebrations[celebrationId][currentType] = [{ id, type }, ...prevArrays];
          }
          return draft;
        });
      } catch (err) {
        store.set('sentryError', err, ' /Clip.js Component and download clip function.');
      }
    },
    []
  );
  return useMemo(() => [fn], [fn]);
};

export const useSubmitClip = ({ type }) => {
  const {
    query: { id },
    asPath,
  } = useRouter();
  const isPublic = asPath.includes('/invitations/');

  const [addCliptoEntity] = useAddClipToEntities();
  const receivePayload = useReceivePayload();
  const [{ loading, data, error }, dispatch] = useRequestReducer();
  const [createMedium, { progress }] = useCreateMedium({ type });

  const fn = useCallback(
    async ({ file, name, selectedGif }) => {
      dispatch({ type: 'start' });
      try {
        const stVolume = type === 'video' ? 0.01 : 0.2;
        let clipAttributes = {
          name,
          state: 'in_clipboard',
        };
        if (type === 'video') {
          try {
            const { duration } = await getMetadata(file);
            clipAttributes['trim-end'] = duration;
          } catch (err) {
            console.log(err);
          }
        }
        if (type === 'image' && selectedGif) {
          const { gifUrl, videoUrl, title, gifID, position } = selectedGif;
          clipAttributes['giphy-mp4'] = videoUrl;
          clipAttributes['giphy-id'] = gifID;
          clipAttributes['giphy-gif-url'] = gifUrl;
          clipAttributes['giphy-title'] = title;
          if (position) clipAttributes.position = position;
        } else {
          clipAttributes = {
            'trim-start': 0,
            // 'trim-end': 5,
            'soundtrack-volume': stVolume,
            ...clipAttributes,
          };
        }
        const clipRes = await ax().post(
          `/v3/${isPublic ? 'public/' : ''}celebrations/${id}/celebration_${type}_clips`,
          { data: { attributes: clipAttributes } }
        );
        const { id: clipId, attributes: clipAttributesResp } = clipRes.data.data;

        receivePayload(clipRes);

        const attributes = {
          'owner-id': clipId,
          'owner-type': 'CelebrationClip',
        };

        if (file) {
          const mediumRes = await createMedium({ file: file || clipAttributes, attributes });
          receivePayload(mediumRes);
        }

        // if (addToClipboard) mutate();
        addCliptoEntity(`celebration-${type}-clips`, clipId, id, clipAttributesResp);
        dispatch({ type: 'success', data: 'clip submitted' });
      } catch (err) {
        dispatch({ type: 'error', error: err.response || err });
      }
    },
    [createMedium, type, id, data, dispatch]
  );
  return useMemo(
    () => [fn, { loading, error, data, progress }],
    [fn, loading, error, data, progress]
  );
};

export const useDownloadClip = () => {
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);

  const fn = useCallback(async (fileUrl, filename, type) => {
    try {
      setDownloading(true);
      const response = await fetch(fileUrl);
      const total = Number(response.headers.get('content-length'));
      const reader = response.body.getReader();
      let bytesReceived = 0;
      const chunks = []; // array of received binary chunks (comprises the body)
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        bytesReceived += value.length;
        setProgress(Math.ceil((bytesReceived / total) * 100));
      }
      const chunksAll = new Uint8Array(bytesReceived); // (4.1)
      let position = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
      }

      const blobFile = new Blob(chunks);
      const blob = await new File([blobFile], 'celebrate-clip', { type });
      setDownloading(false);
      saveAs(blob, filename);
    } catch (err) {
      store.set('sentryError', err, ' /Clip.js Component and download clip function.');
    }
  }, []);
  return useMemo(() => [fn, { downloading, progress }], [fn, downloading, progress]);
};

export const useClipDuration = ({ id, type }) => {
  const clip = useClip({ id, type });
  return clip['image-duration'] || clip['trim-end'] - clip['trim-start'];
};

export const useClipThumbnail = ({ id, type, size = 8 }) => {
  const clip = useClip({ id, type });
  if (clip && clip['giphy-gif-url']) {
    return clip['giphy-gif-url'];
  }
  const clipMedium = clip.video || clip.image;
  const medium = useEntity(clipMedium);
  if (!medium) return undefined;
  return (
    get(medium, `['image-versions'][${size}].url`, '') ||
    medium['preview-url'] ||
    medium.tmpURL ||
    medium['asset-url']
  );
};

export const useClipAuthorName = ({ id, type }) => {
  const clip = useClip({ id, type });
  return useMemberName((clip && clip.author) || {});
};

export const useClipName = ({ id, type }) => {
  const clip = useClip({ id, type });
  const authorName = useClipAuthorName({ id, type });
  return clip.name || authorName || '';
};

export const useVideoClipDuration = (id) => {
  const celebrationClip = useEntity({ type: 'celebration-video-clips', id });
  const video = useEntity({ type: 'videos', id: celebrationClip?.video?.id });
  // TODO: this returns either false or a string depending on if the clip is a video
  // could be cleaned up w/ consistent return type and/or extended to use for other clip types
  if (!video) return 0;
  return video.duration;
};

export const useVideoClipUploadStatus = (id, isForImage = false) => {
  if (isForImage) {
    const celebrationClip = useEntity({ type: 'celebration-image-clips', id });
    const image = useEntity({ type: 'images', id: celebrationClip?.image?.id });
    if (!image) return false;
    return image.state;
  }

  const celebrationClip = useEntity({ type: 'celebration-video-clips', id });
  const video = useEntity({ type: 'videos', id: celebrationClip?.video?.id });
  // TODO: this returns either false or a string depending on if the clip is a video
  // could be cleaned up w/ consistent return type and/or extended to use for other clip types
  if (!video) return false;
  return video.state;
};

export const useCelebrationPrintableLayers = (layers) => {
  if (!layers || layers.length === 0) return [];

  return layers.map(({ id }) => useEntity({ type: 'celebration-printable-layers', id }));
};
