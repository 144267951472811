import { useMemo, useCallback, useReducer } from 'react';
import useSWR, { mutate } from 'swr';
import store from 'store';
import { useRouter } from 'next/router';
import normalize from 'jsonapi-normalizer';
import { fetcher, ax } from '@/lib/api';
import { useReceivePayload } from '@/lib/entities';
import { checkIfIdExist } from '@/lib/helper';

export const useRequestReducer = (initialState = {}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'start':
        return { ...state, loading: true, data: null, error: null };
      case 'error':
        return { ...state, loading: false, error: action.error };
      case 'success':
        return { ...state, loading: false, data: action.data };
      case 'progress':
        return { ...state, progress: action.progress };
      default:
        return state;
    }
  };
  return useReducer(reducer, {
    loading: null,
    error: null,
    data: null,
    progress: 0,
    ...initialState,
  });
};

export const useResourceRequest = (method, url) => {
  const [state, dispatch] = useRequestReducer();
  const receivePayload = useReceivePayload();

  const req = useCallback(
    async (attributes, payload) => {
      dispatch({ type: 'start' });
      const config = { method, url };
      if (attributes) {
        config.data = { data: { attributes } };
      }
      if (payload) {
        config.data = payload;
      }
      let res;
      try {
        res = await ax().request(config);
        receivePayload(res);
        mutate(url, res);
        dispatch({ type: 'success', data: res });
      } catch (err) {
        dispatch({ type: 'error', error: err.response });
      }
      return res;
    },
    [url, method, dispatch, receivePayload]
  );

  return useMemo(() => [req, state], [req, state]);
};

export const useCelebrationPath = (celeId = null) => {
  const {
    query: { id: celebrationId },
  } = useRouter();
  if (celeId || celebrationId || store.get('new-celebration-id'))
    return `/v3/celebrations/${celeId || celebrationId || store.get('new-celebration-id')}`;
  return null;
};
export const useCelebrationResourceRequest = (method, url, id) => {
  const celebrationPath = useCelebrationPath(id);
  return useResourceRequest(method, `${celebrationPath}/${url}`);
};

export const usePatchResource = (path) => {
  return useResourceRequest('patch', path);
};

export const underscore = (dashString = '') => dashString && dashString.replace(/-/g, '_');

export const useGetCelebrations = () => useSWR(`/v3/celebrations`);
export const useGetCelebration = (id, url) =>
  useSWR(checkIfIdExist(id) ? url || `/v3/celebrations/${id}?shallow=true` : null);
export const useGetCelebrationOccasions = () =>
  useSWR('/v3/celebration_occasions', fetcher, { revalidateOnFocus: false });
export const useGetCelebrationTopics = (id) =>
  useSWR(id ? `/v3/celebrations/${id}/celebration_topics` : null);
export const useGetCurrentMember = () => useSWR('/v3/me');

export const usePatchCelebration = (id) => usePatchResource(`/v3/celebrations/${id}`);

export const usePatchClip = ({ id, type }) => {
  return useCelebrationResourceRequest('patch', `${underscore(type)}/${id}`);
};
export const useDeleteClip = ({ id, type, celebrationId }) =>
  useResourceRequest('delete', `/v3/celebrations/${celebrationId}/${underscore(type)}/${id}`);
export const useGETCelebrationClips = (id) =>
  useSWR(() => (id ? `/v3/celebrations/${id}/celebration_clips` : null));
export const useGETClipSWR = (media, id) => {
  const celebrationPath = useCelebrationPath(id);
  return useSWR(media ? `${celebrationPath}/${underscore(media.type)}/${media.id}` : null);
};

export const useStockImages = () =>
  useSWR('/v3/stock_images', fetcher, { revalidateOnFocus: false });

export const useNormalized = (data) =>
  useMemo(() => {
    if (!data) return undefined;
    return normalize(data);
  }, [data]);
