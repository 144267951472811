import { useEntity } from '@/lib/entities';

export const useImage = ({ id, size = 0 }) => {
  const image = useEntity({ type: 'images', id });

  // return useMemo(() => {
  if (!image) return undefined;
  if (image.tmpURL) return image.tmpURL;
  if (image.state === 'finished' && image['image-versions'])
    return image['image-versions'][size].url;
  return image['asset-url'];
  // }, [image]);
};

export const useImageWithDetails = ({ id, size = 0 }) => {
  const image = useEntity({ type: 'images', id });

  // return useMemo(() => {
  if (!image) return undefined;
  if (image.tmpURL) return { url: image.tmpURL, ...image };
  if (image.state === 'finished' && image['image-versions'])
    return { url: image['image-versions'][size].url, ...image };
  return { url: image['asset-url'], ...image };
  // }, [image]);
};

export const useRelatedImage = ({ id, type, relation, size = 8 }) => {
  const entity = useEntity({ id, type });
  const params = {
    size,
    type: 'images',
    id: entity ? entity[relation] && entity[relation].id : '',
  };
  return useImage(params);
};

export default useImage;
