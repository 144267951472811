import { Text } from 'rebass';
import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import useSWR from 'swr';
import { useImmer } from 'use-immer';
import { fetcher } from '@/lib/api';
import { useIsMaintenanceModeEnabled } from '@/hooks/celebration';
import AnimatedDialog from './app/AnimatedDialog';
import ErrorBanner from './app/banners/ErrorBanner';

const FlashMessageContext = createContext();
const ProduceFlashMessageContext = createContext();

// inspired by rails flash messages
// provide a hook to update context on a user message that should be on the next page
// provide a banner which stores that context message into local state and clears it from app state
// so that it only renders once

const initialMessageState = {
  type: '',
  text: '',
  heading: '',
  size: '',
};

function FlashMessages({ children }) {
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [htmlContent, setHtmlContent] = useState('');

  const [value, produce] = useImmer(initialMessageState);
  useSWR('/v3/celebrate_settings', fetcher, { refreshInterval });
  const { maintenanceMode, maintenanceMessage } = useIsMaintenanceModeEnabled();

  useEffect(() => {
    if (maintenanceMode) {
      setRefreshInterval(15000);
      setHtmlContent(maintenanceMessage);
    }

    if (refreshInterval !== null && !maintenanceMode) setRefreshInterval(0);
  }, [maintenanceMode]);

  return (
    <>
      <AnimatedDialog
        contentProps={{ width: '90vw', maxWidth: 500 }}
        isOpen={!!maintenanceMode}
        mt="-24px"
      >
        <Text
          p="40px 20px"
          fontSize={['30px']}
          textAlign="center"
          m="auto"
          dangerouslySetInnerHTML={{
            __html: htmlContent,
          }}
        />
      </AnimatedDialog>
      <FlashMessageContext.Provider value={value}>
        <ProduceFlashMessageContext.Provider value={produce}>
          {children}
        </ProduceFlashMessageContext.Provider>
      </FlashMessageContext.Provider>
    </>
  );
}

const useProduceFlashMessage = () => useContext(ProduceFlashMessageContext);
export const useSetFlashMessage = () => {
  const produceFlashMessage = useProduceFlashMessage();

  return useCallback(
    (values) => {
      produceFlashMessage((draft) => {
        Object.keys(values).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          draft[key] = values[key];
        });
      });
    },
    [produceFlashMessage]
  );
};
const useResetFlashMessage = () => {
  const setMessage = useSetFlashMessage();
  return useCallback(() => {
    setMessage(initialMessageState);
  }, [setMessage]);
};
const useFlashMessage = () => useContext(FlashMessageContext);

export function FlashMessagesBanner(props) {
  const nextFlashMessage = useFlashMessage();
  const resetFlashMessage = useResetFlashMessage();
  const [localMessage, setLocalMessage] = useState(null);

  useEffect(() => {
    if (Object.values(nextFlashMessage).some((el) => !!el)) {
      setLocalMessage(nextFlashMessage);
      resetFlashMessage();
    }
  }, [nextFlashMessage, resetFlashMessage]);

  if (localMessage) {
    const { type, ...bannerProps } = localMessage;
    if (type === 'error') {
      return (
        <ErrorBanner
          onClose={() => {
            setLocalMessage(null);
          }}
          {...bannerProps}
          {...props}
        />
      );
    }
  }

  return null;
}

export default FlashMessages;
