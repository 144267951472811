import React, { useState, createContext, useCallback, useContext } from 'react';
import { v4 } from 'uuid';
import { Flex, Text, Box, Button } from 'rebass';
import { motion } from 'framer-motion';
import { FiX } from 'react-icons/fi';

const ConfirmationOverlayManualContext = createContext({});

function Overlay({ icon, message, onClose }) {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      sx={{ position: 'fixed', zIndex: 10000, top: 0, left: 0 }}
      onClick={onClose}
    >
      <motion.div
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: 0, y: -10 }}
        transition={{ delay: 0.7, duration: 1000 }}
      >
        <Box
          bg="rgba(0,0,0,.7)"
          sx={{ borderRadius: 'large', boxShadow: 'large', maxWidth: '300px' }}
          onClick={onClose}
        >
          <Button
            p={0}
            bg="transparent"
            type="button"
            onClick={onClose}
            sx={{
              marginLeft: '-32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              height: 32,
              width: 32,
              outline: 'transparent',
              float: 'right',
            }}
          >
            <Text as={FiX} />
          </Button>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={6}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose(e);
            }}
          >
            <Text as={icon} size={48} color="white" />
            <Text color="white" pt={4} textAlign="center">
              {message}
            </Text>
          </Flex>
        </Box>
      </motion.div>
    </Flex>
  );
}

function ConfirmationOverlayManual({ children }) {
  const [{ icon, message, id }, setOverlay] = useState({});

  const createOverlay = useCallback(
    (props) => {
      const newId = v4();
      setOverlay({ id: newId, ...props });
    },
    [setOverlay]
  );

  return (
    <ConfirmationOverlayManualContext.Provider value={createOverlay}>
      {children}
      {id && <Overlay icon={icon} message={message} key={id} onClose={() => setOverlay({})} />}
    </ConfirmationOverlayManualContext.Provider>
  );
}

export const useCreateConfirmationManual = () => useContext(ConfirmationOverlayManualContext);

export default ConfirmationOverlayManual;
