import React, { useContext, createContext, useState } from 'react';

const PendingCelebrationContext = createContext([]);

function PendingCelebration(props) {
  const state = useState(undefined);
  return <PendingCelebrationContext.Provider value={state} {...props} />;
}

export const useSetPendingCelebration = () => {
  const [, setPendingCelebration] = useContext(PendingCelebrationContext);
  return setPendingCelebration;
};

export default PendingCelebration;
