import { useMemo } from 'react';
import store from 'store';
import UAParser from 'ua-parser-js';
import {
  maxVideoVolumeServerValue,
  maxVideoVolumeSliderValue,
  SEE_EXAMPLE_LINK,
  singleVideoTemplateId,
  videoGuestBookTemplateId,
  VIDEO_GUEST_BOOK,
  viewChangePixels,
} from './constants';
import { postPrivateEventDetails, sendErrorToBackend } from './api';

const parser = new UAParser();
const browserName = parser.getResult().browser.name;

export const useHasMediaRecorder = () => useMemo(() => 'MediaRecorder' in window, [window]);

export const extensionRegex = /(?:\.([^.]+))?$/;

export function getMediaIdFromUploadKey(key) {
  const idWithExtension = key.split('/')[1];
  const mediaId = idWithExtension.split('.')[0];
  store.set('mediaId', mediaId);
  return mediaId;
}

export function sendTrackingEvent(eventName, attr = {}) {
  postPrivateEventDetails(`${eventName}`, {
    'subject-id': store.get('mediaId'),
    'subject-type': 'Medium',
    ...attr,
  });
}

export function clearLocalStorageItems() {
  store.remove('Media Uploading');
  store.remove('uploadingID');
  store.remove('mediaId');
}

export function checkIfIdExist(id) {
  return id && id !== 'undefined';
}

export const checkIsIpadUser = () =>
  typeof window !== 'undefined'
    ? parser.getDevice().model === 'iPad' ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 5)
    : false;

export function isTouchDevice() {
  return typeof window !== 'undefined'
    ? 'ontouchstart' in window || navigator.maxTouchPoints
    : false;
}
export function setFileType(type) {
  if (type.includes('/quicktime')) return type.replace('/quicktime', '/mov');

  if (type.includes('/x-matroska')) return type.replace('/x-matroska', '/mkv');

  if (type.includes('/3gpp')) return type.replace('/3gpp', '/3gp');

  if (type.includes('application/octet-stream'))
    return type.replace('application/octet-stream', 'video/webm');

  if (type.includes('x-m4v')) return type.replace('x-m4v', 'm4v');

  if (type.includes('x-m4a')) return type.replace('x-m4a', 'm4a');
  if (type.includes('x-')) return type.replace('x-', '');

  return type;
}

export function renderPositionForVGBImage(position) {
  if (position?.x === 0 && position?.y === 0) return null;

  return position;
}

export function setFileName(name, type) {
  const fileNameWithoutSpaceAndots = name.replace(/\./g, '');
  return extensionRegex.exec(fileNameWithoutSpaceAndots)[0]
    ? fileNameWithoutSpaceAndots
    : `${fileNameWithoutSpaceAndots.replace(/\s/g, '')}.${type.split('/')[1]}`;
}

export const appStoreUrl = 'https://apps.apple.com/ca/app/celebrate-make-someones-day/id1538999433';

export const getCardsLength = (width) => {
  if (width < viewChangePixels) return '1fr';
  return '1fr 2fr';
};

export const getAspectRatio = (width) => {
  const vidWidth = width - 156; // 1436 - 156 = 1280
  return vidWidth / (16 / 9); // find 16/9 ratio height
};

export function sortPosition() {
  return function (a, b) {
    // equal items sort equally
    if (a.position === b.position) {
      return 0;
    }
    // nulls sort after anything else
    if (a.position === null) {
      return 1;
    }
    if (b.position === null) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    return a.position < b.position ? -1 : 1;
  };
}

export function relativeVideoVolume(volume, fromServer) {
  const { Math } = window;
  const minConvergenceVolume = 1; // i.e. scale is linear up to 100% volume
  if (volume <= minConvergenceVolume) {
    return volume;
  }
  const minv = Math.log(minConvergenceVolume);
  const maxv = Math.log(maxVideoVolumeServerValue);
  const scale = (maxv - minv) / (maxVideoVolumeSliderValue - minConvergenceVolume);
  if (fromServer) {
    return (Math.log(volume) - minv) / scale + minConvergenceVolume;
  }
  return Math.exp(minv + scale * (volume - minConvergenceVolume));
}

export function retryUppyUpload() {
  if (typeof document !== 'undefined' && document.querySelector('[aria-label="Retry upload"]'))
    document.querySelector('[aria-label="Retry upload"]').click();
}

export function pauseUppyUpload() {
  if (typeof document !== 'undefined' && document.querySelector('[aria-label="Pause"]'))
    document.querySelector('[aria-label="Pause"]').click();
}

export function uppyContentBarOpacity(opacity) {
  if (
    typeof document !== 'undefined' &&
    document.getElementsByClassName('uppy-StatusBar-content').length > 0
  )
    document.getElementsByClassName('uppy-StatusBar-content')[0].style.opacity = opacity;
}

export function resumeUppyUpload() {
  if (browserName?.includes('Safari') && !document.querySelector('[aria-label="Resume"]')) {
    retryUppyUpload();
    uppyContentBarOpacity(1);
  } else if (document.querySelector('[aria-label="Resume"]'))
    document.querySelector('[aria-label="Resume"]').click();
}

export function cancelUppyUpload() {
  if (document.querySelector('[aria-label="Cancel"]'))
    document.querySelector('[aria-label="Cancel"]').click();
}

export function getIDFromKey(key) {
  /* eslint-disable no-useless-escape */
  store.set('uploadingID', key.includes('/') ? key.split(/[\/.]+/)[1] : key);
}

export function checkInternetFailureError(error, elseBlock) {
  if (store.get('uploadingID')) sendErrorToBackend(store.get('uploadingID'), error?.message);

  if (
    (error?.isNetworkError ||
      error?.message?.toLowerCase().includes('the internet connection appears to be offline')) &&
    browserName?.includes('Safari')
  ) {
    pauseUppyUpload();
    uppyContentBarOpacity(0);
  } else elseBlock();
}

export function removeParam(key, sourceURL) {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArr = [];
  const queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      // eslint-disable-next-line prefer-destructuring
      param = paramsArr[i].split('=')[0];
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length) rtn = `${rtn}?${paramsArr.join('&')}`;
  }
  return rtn;
}

export const checkImage = (url) =>
  url.includes('.jpg') ||
  url.includes('.jpeg') ||
  url.includes('.gif') ||
  url.includes('.svg') ||
  url.includes('.png');

export function capitalizeFirstLetter(string = '') {
  if (typeof string === 'string') return string.charAt(0).toUpperCase() + string.slice(1);

  return string;
}

export function clearCelebrationId() {
  if (store.get('new-celebration-id')) store.remove('new-celebration-id');
  store.remove('new-celebration-creation-step');
  store.remove('new-celebration-template-selected');
}

export function getDefaultOccasion(templateId) {
  if (templateId === singleVideoTemplateId) return 'Birthday';
  if (templateId === videoGuestBookTemplateId) return 'Wedding';
  return null;
}

export function compare(a, b) {
  if (a.label < b.label && !a.label.includes('Custom')) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

export function extractDollarValueFromString(str) {
  try {
    const pattern = /\$[\d,]+(?:\.\d+)?/;
    const match = str.match(pattern);
    return match[0];
  } catch {
    return str;
  }
}

export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export function getCelebrationPrefix(isDirectCelebrationCreation) {
  let theme = 'general_';
  if (isDirectCelebrationCreation === 'videoguestbook') theme = 'vgb_';
  else if (isDirectCelebrationCreation === 'classic') theme = 'classic_';
  else if (isDirectCelebrationCreation === 'qanda') theme = 'qanda_';

  return theme;
}

export function checkIfItsDirecrCelebration(query) {
  if (query?.videoguestbook) return 'videoguestbook';
  if (query?.classic) return 'classic';
  if (query?.qanda) return 'qanda';
  return false;
}

export function getParameters(queryString) {
  const params = new URLSearchParams(queryString);
  const utmParameters = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params.entries()) {
    utmParameters[key] = value;
  }

  return utmParameters;
}

export function convertToYYYYMMDD(dateString) {
  if (dateString && dateString.includes('T')) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  }
  return dateString;
}

export function calculateLetterSpacing(fontSize, percentage) {
  return `${fontSize * (percentage / 100)}px`;
}

export function getDaysDiff(endDate) {
  if (!endDate) return '-';
  const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const start = new Date(); // Convert start date string to Date object
  const end = new Date(endDate); // Convert end date string to Date object

  // Calculate the difference in milliseconds
  const diffMilliseconds = Math.abs(end - start.setHours(0, 0, 0, 0));

  // Calculate the difference in days
  const diffDays = Math.round(diffMilliseconds / oneDay);

  return diffDays;
}

export function getDateParts(dateString) {
  if (!dateString)
    return {
      day: '-',
      month: '-',
      year: '-',
    };

  const date = new Date(dateString);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  // Extract date, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Create key-value pairs
  const dateParts = {
    day,
    month,
    year,
  };

  return dateParts;
}

export const seeAnExample = (celebrationId) => {
  sendTrackingEvent(`celebration_tapped_see_example`, {
    context: {
      url_text: 'See an Example',
      url: SEE_EXAMPLE_LINK,
    },
    'subject-type': 'Celebration',
    'subject-id': celebrationId,
  });
};

export const showLockIcon = (celebration, clip, index) =>
  celebration?.theme === VIDEO_GUEST_BOOK &&
  !celebration?.paid &&
  index > 1 &&
  !(clip?.['giphy-id'] || clip?.['stock-image-id']);

export const coffeeVideoBookPrices = {
  USA: {
    upTo10Minutes: 54,
    tenTo30Minutes: 74,
    thirtyTo60Minutes: 84,
  },
  // Canada: {
  //   upTo10Minutes: 71,
  //   tenTo30Minutes: 91,
  //   thirtyTo60Minutes: 101,
  // },
  // UK: {
  //   upTo10Minutes: 76,
  //   tenTo30Minutes: 96,
  //   thirtyTo60Minutes: 106,
  // },
  // Australia: {
  //   upTo10Minutes: 84,
  //   tenTo30Minutes: 104,
  //   thirtyTo60Minutes: 114,
  // },
  International: {
    upTo10Minutes: 71,
    tenTo30Minutes: 91,
    thirtyTo60Minutes: 101,
  },
};

export const coffeeVideoBookPrice = 79.99;

export const getcoffeeVideoBookPrice = () => coffeeVideoBookPrice;

export const coffeeVideoBookCoverPhotos = [
  { url: 'https://public.sendheirloom.com/Covers/400/Memories.jpg', name: 'MEMORIES' },
  { url: 'https://public.sendheirloom.com/Covers/400/Wedding.jpg', name: 'WEDDING' },
  { url: 'https://public.sendheirloom.com/Covers/400/Love.jpg', name: 'LOVE' },
  { url: 'https://public.sendheirloom.com/Covers/400/Good%20Times.jpg', name: 'GOODTIMES' },
  { url: 'https://public.sendheirloom.com/Covers/400/Xmas.jpg', name: 'CHRISTMASRED' },
  { url: 'https://public.sendheirloom.com/Covers/400/Xmas%20Deer.jpg', name: 'CHRISTMASDEER' },
  { url: 'https://public.sendheirloom.com/Covers/400/Xmas%20Snow.jpg', name: 'CHRISTMASSNOW' },
  { url: 'https://public.sendheirloom.com/Covers/400/Holidays%20Snow.jpg', name: 'HOLIDAYSNOW' },
  { url: 'https://public.sendheirloom.com/Covers/400/Holidays%20Retro.jpg', name: 'HOLIDAYRETRO' },
  { url: 'https://public.sendheirloom.com/Covers/400/Baby.jpg', name: 'BABY' },
  { url: 'https://public.sendheirloom.com/Covers/400/Flowers.jpg', name: 'FLOWERS' },
  { url: 'https://public.sendheirloom.com/Covers/400/Birthday.jpg', name: 'BIRTHDAY' },
  { url: 'https://public.sendheirloom.com/Covers/400/Blank.jpg', name: 'BLANK' },
  { url: 'https://public.sendheirloom.com/Covers/400/Hearts.jpg', name: 'HEARTS' },
  { url: 'https://public.sendheirloom.com/Covers/400/Thank%20You.jpg', name: 'THANKYOU' },
  { url: 'https://public.sendheirloom.com/Covers/400/Animal.jpg', name: 'ANIMAL' },
];
