import useSWR from 'swr';
import { get } from 'lodash';
import { useEntity } from '@/lib/entities';
import { useRelatedImage } from '@/hooks/image';

const useMember = (id = '') => useEntity({ id, type: 'members' });

export const useMemberName = ({ id = '' }) => {
  const member = useMember(id);
  if (!member) return '';
  return (
    member['display-name'] ||
    member['full-name'] ||
    `${member['first-name']} ${member['last-name']}`
  );
};

export const useCurrentMember = () => {
  const { data } = useSWR('/v3/me');
  const id = get(data, 'data.data.id', '');
  return useEntity({ type: 'members', id }) || null;
};

export const useCurrentMemberEmail = () => {
  const currentMember = useCurrentMember();
  return currentMember?.email;
};

export const useCurrentMemberName = () => {
  const { data } = useSWR('/v3/me');
  if (!data) return '';
  const { attributes } = data.data.data || data.data;
  return (
    attributes['display-name'] ||
    attributes['full-name'] ||
    `${attributes['first-name']} ${attributes['last-name']}`
  );
};

export const useMemberAvatar = (id = '', size = 8) =>
  useRelatedImage({ id, type: 'members', relation: 'avatar-image', size });
