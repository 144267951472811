import { useMemo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import Axios from 'axios';
import store from 'store';
import UAParser from 'ua-parser-js';
import { mutate } from 'swr';
import { capitalize, get, uniqBy } from 'lodash';
import { validate } from 'uuid';
import { useRelatedImage } from '@/hooks/image';
import {
  useEntity,
  useEntities,
  useReceivePayload,
  useRelatedEntities,
  useCreateEntity,
} from '@/lib/entities';
import { ax, appOrigin } from '@/lib/api';
import { celebrationSettingId, VIDEO_GUEST_BOOK, viewChangePixels } from '@/lib/constants';
import { checkIfIdExist, checkIsIpadUser } from '@/lib/helper';
import { useGetCelebrationTopics, useRequestReducer } from './api';
// eslint-disable-next-line import/no-cycle
import { useVideoClipUploadStatus, useVideoClipDuration, useCheckIsIosUser } from './clip';
// import { isUuid } from 'uuidv4';
// import { useCelebrationClips } from './clip';

export const useMobileCheckHook = () => {
  const { width: asWidth } = useWindowSize();
  const parser = new UAParser();
  const osName = parser.getResult().os.name;
  const isIpadUser = checkIsIpadUser();
  const isMobileUser = ['iOS', 'Android'].includes(osName) || isIpadUser;
  let width = asWidth;
  if (!isMobileUser) return false;
  if (window.screen.width) {
    width = window.screen.width;
    if (window.navigator.maxTouchPoints === 0) width = 1030;
  }
  return width < viewChangePixels || isIpadUser;
};

export const useLandScapeCheckHook = () => {
  const [isLandscape, setIsLandscape] = useState(null);
  const [orientation, setOrientation] = useState(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const iOSUser = useCheckIsIosUser();

  function changeEventListener(e) {
    setIsLandscape(!e.matches);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsBuffering(false);
    }, 500);
  }, [orientation]);

  function orientationChange() {
    if (iOSUser) setIsBuffering(true);
    setOrientation(window.orientation);
  }

  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');
    portrait.addEventListener('change', changeEventListener);
    window.addEventListener('orientationchange', orientationChange, false);
    setIsLandscape(!portrait.matches && window.navigator.maxTouchPoints !== 0);
    orientationChange();
  }, []);

  return useMemo(
    () => [isLandscape, orientation, isBuffering],
    [isLandscape, orientation, isBuffering]
  );
};

export const useCelebration = (id) => useEntity({ id, type: 'celebrations' });

export const useCelebrationHaveClips = () => {
  const {
    query: { id },
  } = useRouter();
  const celebration = useCelebration(id);
  return celebration && celebration?.['visible-clips-count'] > 0;
};

export const useCelebrationInRoute = () => {
  const {
    query: { id },
  } = useRouter();
  return useCelebration(id);
};

export function getAudioDuration(id, type) {
  const track = useEntity({ id, type }) || {};
  const audio = useEntity(track?.audio);
  if (!audio) return 0;
  return audio.duration / 1000;
}

export const useIsMaintenanceModeEnabled = () => {
  const celebrateSettigns = useEntity({
    type: 'celebrate-settings',
    id: celebrationSettingId,
  });
  return useMemo(
    () => ({
      maintenanceMessage: celebrateSettigns?.['maintenance-message'],
      maintenanceMode: celebrateSettigns?.['maintenance-mode'],
    }),
    [celebrateSettigns]
  );
};

export const useIsCustomRecorderEnabled = () => {
  const celebrateSettigns = useEntity({
    type: 'celebrate-settings',
    id: celebrationSettingId,
  });
  return useMemo(() => celebrateSettigns?.['use-custom-video-recorder'], [celebrateSettigns]);
};

export const useCelebrationSoundTracks = (id) => {
  const soundTracks = useRelatedEntities({
    type: 'celebrations',
    id,
    relationship: 'sound-tracks',
  });
  return useMemo(() => (soundTracks || []).sort((a, b) => a.position - b.position), [soundTracks]);
};

export const useClipEntities = () => {
  const videoClips = useEntities({ type: 'celebration-video-clips', fallback: {} });
  const imageClips = useEntities({ type: 'celebration-image-clips', fallback: {} });
  return useMemo(() => ({ ...videoClips, ...imageClips }), [videoClips, imageClips]);
};

export const usePrintableTemplateEntities = () => {
  const printableTemplates = useEntities({ type: 'celebration-printable-templates', fallback: {} });
  return useMemo(() => ({ ...printableTemplates }), [printableTemplates]);
};

export const usePrintableEntities = () => {
  const printables = useEntities({ type: 'celebration-printables', fallback: {} }) || null;
  return useMemo(() => {
    const selectedFrontPrintable = printables
      ? printables[Object.keys(printables).filter((print) => printables[print].page === 'front')[0]]
      : undefined;
    const selectedBackPrintable = printables
      ? printables[Object.keys(printables).filter((print) => printables[print].page === 'back')[0]]
      : undefined;
    return [selectedFrontPrintable, selectedBackPrintable, printables];
  }, [printables]);
};
export const useAllCelebrationClips = (id) => {
  const clips = useRelatedEntities({ id, type: 'celebrations', relationship: 'visible-clips' });

  return useMemo(() => {
    if (!clips) return [];
    return clips
      .filter(
        (clip) => !(clip?.['giphy-id'] || clip?.['stock-image-id']) && clip.state !== 'deleted'
      )
      .sort((a, b) => (a.position || 0) - (b.position || 0));
  }, [clips]);
};

export const useCelebrationClips = (id, { state = 'in_timeline' } = {}) => {
  const celebration = useCelebration(id);
  const imageClips = useEntities({ type: 'celebration-image-clips' });
  const videoClips = useEntities({ type: 'celebration-video-clips' });

  const clips = { 'celebration-image-clips': imageClips, 'celebration-video-clips': videoClips };
  let clipsWithStatus = [];
  if (celebration) {
    clipsWithStatus = [
      ...(celebration['video-clips'] || []),
      ...(celebration['image-clips'] || []),
    ].map((el) => {
      const clip = { ...el };
      clip.position = clips?.[clip.type]?.[clip.id]?.position;
      clip.duration = useVideoClipDuration(el.id) / 1000;
      clip.uploadStatus = useVideoClipUploadStatus(
        el.id,
        el.type === 'celebration-image-clips' && true
      );
      return clip;
    });
  }

  return useMemo(() => {
    if (!celebration) return [];

    // const clipResults = [
    //   ...(celebration['video-clips'] || []),
    //   ...(celebration['image-clips'] || []),
    // ];

    return uniqBy(
      clipsWithStatus
        .filter((clip) => {
          const entity = get(clips, `[${clip.type}][${clip.id}]`);
          return entity && entity.state === state;
        })
        .sort((a, b) => {
          const entityA = clips[a.type][a.id];
          const entityB = clips[b.type][b.id];
          return (entityA.position || 0) - (entityB.position || 0);
        }),
      'id'
    );
  }, [clips, state, celebration, clipsWithStatus]);
};

export const useOccasionDescription = (id) => {
  const celebration = useCelebration(id);
  return celebration?.['occasion-description-long'];
};

export const useCelebrationTheme = (id) => {
  const { theme } = useCelebration(id) || {};

  return useMemo(() => {
    return theme;
  }, [theme]);
};

export const useIsSombreCelebrationTheme = (id) => {
  const { theme } = useCelebration(id) || {};

  return useMemo(() => {
    return theme === 'sombre';
  }, [theme]);
};

export const useIsVideoGuestBookCelebrationTheme = (id) => {
  const { theme } = useCelebration(id) || {};

  return useMemo(() => {
    return theme === VIDEO_GUEST_BOOK;
  }, [theme]);
};

export const useIsLHSCelebrationTheme = (id) => {
  const { theme } = useCelebration(id) || {};

  return useMemo(() => {
    return theme === 'foundation';
  }, [theme]);
};

export const useIsSimpleFoundationCelebrationTheme = (id) => {
  const { theme } = useCelebration(id) || {};

  return useMemo(() => {
    return theme === 'simple_foundation';
  }, [theme]);
};

export const useCelebrationTitle = (id) => {
  const { title } = useCelebration(id) || {};

  return useMemo(() => {
    return title;
  }, [title]);
};

export const useCelebrationRecipient = (id) => {
  const { 'for-whom': subjectName } = useCelebration(id) || {};

  return subjectName || 'recipient';
};

export const useCelebrationTopicText = (id) => {
  const celebration = useCelebration(id);
  const topic = useEntity(celebration?.['celebration-topic']);
  if (!topic) return '';
  if (topic.nothing) return '';
  if (topic.other) {
    return celebration['custom-topic'];
  }
  return topic.description;
};

export const findSelectedTopics = (id, data, getDetails) => {
  const celebration = useCelebration(id);
  if (data.length > 0 && celebration?.['celebration-celebration-topics']?.length > 0) {
    return (
      celebration?.['celebration-celebration-topics']
        .map((cel) => {
          if (getDetails) {
            let topicDetails = useEntity(cel);
            if (topicDetails?.['celebration-question-clip']) {
              const clip = useEntity({ ...topicDetails['celebration-question-clip'] });
              if (clip) topicDetails = { ...topicDetails, 'text-clips': clip?.['text-clips'] };
            }

            if (topicDetails) {
              const details = data.filter((dat) => dat.id === topicDetails['celebration-topic'].id);
              if (details.length > 0) return { ...details[0].attributes, ...topicDetails };
              return { ...topicDetails };
            }
            return null;
          }
          return useEntity(cel)['celebration-topic'].id;
        })
        .filter((c) => !!c)
        /* eslint-disable no-unsafe-optional-chaining */
        .sort((a, b) => a?.position - b?.position)
    );
  }
  return [];
};

export const useCelebrationSelectedTopics = (id, getDetails = false, parsedData = undefined) => {
  const { data } = useGetCelebrationTopics(parsedData ? null : id);
  const updatedData = parsedData || data?.data?.data || [];
  return findSelectedTopics(id, updatedData, getDetails);
};

export const useCelebrationCoverPhoto = (id, size = 3) => {
  const celebration = useCelebration(id);
  const image = useRelatedImage({ id, type: 'celebrations', relation: 'cover-photo', size });
  const occasionFallback = useRelatedImage({
    ...(celebration?.['celebration-occasion'] || {}),
    relation: 'image',
    size,
  });
  return image || occasionFallback;
};

export const useCelebrationIntroVideo = (id) => {
  const celebration = useCelebration(id);
  return useEntity(celebration?.['invitation-recording']);
};

export const useUpdateCelebrationVideoMedium = ({
  id,
  filename,
  key,
  onStart = () => {},
  onSuccess = () => {},
}) => {
  const receivePayload = useReceivePayload();
  const createEntity = useCreateEntity();
  const [state, dispatch] = useRequestReducer();
  const req = useCallback(
    async (blob) => {
      onStart(blob);

      // eslint-disable-next-line no-param-reassign
      blob.filename = filename;

      const [type] = blob.type.split('/');

      const attributes = {
        type: capitalize(type),
        filename,
        'owner-id': id,
        'owner-type': 'Celebration',
      };

      dispatch({ type: 'start' });

      const mediumResponse = await ax().post(`/v3/media`, {
        data: {
          type: 'Image',
          attributes,
        },
      });
      const tmpUrl = URL.createObjectURL(blob);
      createEntity({ id: mediumResponse.data.id, type: mediumResponse.data.type, tmpUrl });
      receivePayload(mediumResponse.data);

      const { 'upload-url': uploadURL } = mediumResponse.data.data.attributes;
      const { id: mediumId } = mediumResponse.data.data;

      await Axios.put(uploadURL, blob, {
        headers: { 'Content-Type': type },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (totalLength !== null) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            dispatch({ type: 'progress', progress });
          }
        },
      });

      await Promise.all([
        ax().patch(`/v3/celebrations/${id}`, {
          data: { attributes: { [key]: mediumId } },
        }),
        Axios.put(uploadURL, blob, { headers: { 'Content-Type': blob.type } }),
      ]);
      // TODO: find out why success returns undefined
      dispatch({ type: 'success', data: mediumResponse });
      onSuccess(blob);
      mutate(checkIfIdExist(id) ? `/v3/celebrations/${id}?shallow=true` : null);
      return mediumResponse;
    },
    [receivePayload, onStart, onSuccess, id]
  );
  return useMemo(() => [req, state], [req, state]);
};

export const useUpdateCelebrationMedium = ({
  id,
  filename,
  key,
  onStart = () => {},
  onSuccess = () => {},
}) => {
  const receivePayload = useReceivePayload();
  return useCallback(
    (blob) => {
      const process = async () => {
        onStart(blob);
        // eslint-disable-next-line no-param-reassign
        blob.filename = filename;

        const [type] = blob.type.split('/');

        const attributes = {
          type: capitalize(type),
          filename,
          'owner-id': id,
          'owner-type': 'Celebration',
        };

        const mediumResponse = await ax().post(`/v3/media`, {
          data: {
            type: 'Image',
            attributes,
          },
        });
        receivePayload(mediumResponse.data);

        const { 'upload-url': uploadURL } = mediumResponse.data.data.attributes;
        const { id: mediumId } = mediumResponse.data.data;

        await Promise.all([
          ax().patch(`/v3/celebrations/${id}`, {
            data: { attributes: { [key]: mediumId } },
          }),
          Axios.put(uploadURL, blob, { headers: { 'Content-Type': blob.type } }),
        ]);
        mutate(id ? `/v3/celebrations/${id}?shallow=true` : null);
        onSuccess(blob);
      };
      process();
    },
    [receivePayload, onStart, onSuccess, id]
  );
};

export const useCelebrationPublicURL = (id) => {
  const celebration = useCelebration(id);
  const slug = get(celebration, 'slug', id);
  return useMemo(() => {
    if (process.browser) {
      return `${window.location.origin}/invitations/${slug}`;
    }
    return `${appOrigin}invitations/${slug}`;
  }, [slug]);
};

export const useCelebrationVideoURL = (id) => {
  return useMemo(() => {
    if (process.browser) {
      return `${window.location.origin}/dashboard/${id}/final-video`;
    }
    return `${appOrigin}dashboard/${id}/final-video`;
  }, [id]);
};

export const usePublicCelebrationVideoURL = (slug) => {
  return useMemo(() => {
    if (process.browser) {
      return `${window.location.origin}/videos/${slug}`;
    }
    return `${appOrigin}videos/${slug}`;
  }, [slug]);
};

export const useCelebrationIdFromRoute = (id) => {
  const celebrations = useEntities({ type: 'celebrations' });
  return useMemo(() => {
    if (validate(id)) return id;
    return celebrations ? Object.keys(celebrations).find((c) => celebrations[c].slug === id) : '';
  }, [celebrations, id]);
};

export const useCelebrationUploadErrorEmailMailto = (id, memberId) => {
  const title = useCelebrationTitle(id);
  return useCallback(
    (error) => {
      const body = `Upload Failed for the following

Member Id = ${memberId}
Token = ${store.get('token')}
Celebration Id = ${id}
Title = ${title}
Error Message = ${error}
    `;
      const mailto = `mailto:help@celebrate.buzz?subject=${encodeURIComponent(
        'Failed to upload'
      )}&body=${encodeURIComponent(body)}`;
      return mailto;
    },
    [title, id, memberId]
  );
};

export const useDebouncedValue = (initialValue, delay = 500) => {
  const [value, setValue] = useState(initialValue);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const handleDebounce = () => {
      setValue(initialValue);
      setTimeoutId(null);
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(handleDebounce, delay);

    setTimeoutId(newTimeoutId);

    // Cleanup the timeout on unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [initialValue, delay]);

  return value;
};

export const useGetTemplateData = (id) => {
  const [templates, setTemplates] = useState([]);
  const celebration = id ? useCelebration(id) : null;

  async function getTemplates() {
    const { data } = await ax().get(`/v3/my/celebration_templates`);

    if (!celebration?.['celebration-template']?.id) setTemplates(data.data);
    else
      setTemplates(
        data.data.filter((item) => {
          return (
            item.attributes.state !== 'soft_deleted' ||
            item.attributes.id === celebration?.['celebration-template']?.id
          );
        })
      );
  }

  useEffect(() => {
    getTemplates();
  }, [celebration?.['celebration-template']?.id]);

  return [templates, getTemplates];
};
