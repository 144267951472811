import React, { useEffect, useState } from 'react';
import { Detector } from 'react-detect-offline';
import * as Sentry from '@sentry/node';
import store from 'store';
import eventsPlugin from 'store/plugins/events';
import { pauseUppyUpload, resumeUppyUpload } from '@/lib/helper';

function LogsTracker({ internetConnected }) {
  const [sentryError, _setSentryError] = useState([]);
  const sentryErrorRef = React.useRef(sentryError);

  const setErrors = (data) => {
    sentryErrorRef.current = data;
    _setSentryError(data);
  };

  useEffect(() => {
    store.addPlugin(eventsPlugin);
    store.watch('sentryError', function (data) {
      if (!sentryErrorRef.current.includes(data)) setErrors(sentryErrorRef.current.concat(data));
    });
  }, []);

  useEffect(() => {
    if (internetConnected && sentryError.filter((err) => !!err).length > 0) {
      // eslint-disable-next-line no-return-await
      sentryError.map(async (err) => await Sentry.captureException(err));
      sentryErrorRef.current = [];
      _setSentryError([]);
      store.remove('sentryError');
    }
    if (internetConnected) {
      resumeUppyUpload();
    } else {
      pauseUppyUpload();
    }
  }, [sentryError, internetConnected, _setSentryError]);

  return null;
}
function SentryLogsTracker() {
  return <Detector render={({ online }) => <LogsTracker internetConnected={online} />} />;
}

SentryLogsTracker.defaultProps = {};
SentryLogsTracker.propTypes = {};

export default SentryLogsTracker;
