import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from 'react-use';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from '@emotion/styled';
import { overflow, border, boxShadow, layout, zIndex, space, flexbox } from 'styled-system';
import { Flex, Box, Text, Button } from 'rebass';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { useMobileCheckHook } from '@/hooks/celebration';
import { isTouchDevice } from '@/lib/helper';

const StyledDialogContent = styled(DialogContent)`
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => `${props.mt || props.my}px  ${props.mx}px ${props.my}px ${props.mx}px`};
  padding: ${(props) => `${props.p}px`};
  box-shadow: ${(props) => props.boxShadow};
  overflow: ${(props) => props.overflow};
  ${border};
  ${layout};
  ${space};
  ${boxShadow};
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  ${(props) => props.contentCSS};
`;

const StyledDialogOverlay = styled(DialogOverlay)`
  ${overflow};
  ${zIndex};
  ${layout};
  ${space};
  ${flexbox};
  ${space};
`;

// TODO: not sure why contentProps isn't working. contentCSS is but they could be cleaned up/amalgamated
function AnimatedDialog({
  title,
  children,
  zIndex: propzIndex,
  contentProps,
  contentCSS,
  onDismiss,
  isFullScreenModal,
  isFromMobile,
  hideAutoDismiss,
  mt = '-40px',
  allowPinchAndZoom = false,
  isFromPayment,
  ...props
}) {
  const mobileVersion = useMobileCheckHook();
  const { width } = useWindowSize();
  return (
    <StyledDialogOverlay
      {...{ zIndex: propzIndex }}
      alignItems={['center']}
      justifyContent="center"
      p={[0, 3, 5]}
      m={0}
      style={{ background: props.background }}
      onDismiss={
        hideAutoDismiss || !onDismiss
          ? () => {}
          : () => {
              if (window.localStream) {
                window.localStream.getTracks().forEach((track) => {
                  track.stop();
                });
                window.localStream.getVideoTracks().forEach((track) => {
                  track.stop();
                });
                window.localStream.getAudioTracks().forEach((track) => {
                  track.stop();
                });
              }

              onDismiss();
            }
      }
      {...props}
    >
      <StyledDialogContent
        borderRadius="xLarge"
        aria-label={title || 'Dialog'}
        // width="auto"
        mx="auto"
        my={0}
        mt={mobileVersion && !isFullScreenModal ? '20%' : 0}
        p={0}
        boxShadow="large"
        overflow={['visible', 'hidden']}
        contentCSS={`${contentCSS} background:${
          typeof onDismiss === 'function' ? '#FFFFFF' : '#FDFDFD'
        }`}
        {...contentProps}
      >
        {typeof onDismiss === 'function' &&
          (isFromMobile ? ( // Showing cancel button to close the modal
            <Flex
              px={[2, 3]}
              minHeight={width < 500 ? 40 : [60]}
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                position: 'relative',
                zIndex: 2,
                // mt: width < 500 && "30px"
              }}
              width="100%"
            >
              <Text
                color="purples.7"
                onClick={onDismiss}
                sx={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
              >
                Close
              </Text>
            </Flex>
          ) : (
            <Flex
              px={[2, 3]}
              height={[60, 80]}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ position: 'relative', zIndex: 2 }}
              width="100%"
            >
              <Button
                p={1}
                bg="transparent"
                type="button"
                onClick={onDismiss}
                // sx={{
                //   display: 'flex',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   borderRadius: '50%',
                //   height: 32,
                //   width: 32,

                //   outline: 'transparent',
                // }}
              >
                {isFromPayment ? (
                  <Text as={IoMdClose} size={36} color="#FFF" />
                ) : (
                  <Text as={AiFillCloseCircle} size={24} color="purples.7" />
                )}
              </Button>
            </Flex>
          ))}
        <Flex
          flex={1}
          width="100%"
          alignItems="center"
          pt={typeof onDismiss !== 'function' && 3}
          justifyContent="center"
          mt={mt}
        >
          {allowPinchAndZoom && isTouchDevice && mobileVersion ? (
            <TransformWrapper centerZoomedOut centerOnInit limitToBounds>
              <TransformComponent contentStyle={{ width: '100%' }} wrapperStyle={{ width: '100%' }}>
                <Box width="100%">{children}</Box>
              </TransformComponent>
            </TransformWrapper>
          ) : (
            children
          )}
        </Flex>
      </StyledDialogContent>
    </StyledDialogOverlay>
  );
}

AnimatedDialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  zIndex: PropTypes.number,
};

AnimatedDialog.defaultProps = { zIndex: 1000, title: '' };

export default AnimatedDialog;
