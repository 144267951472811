import React, { useContext, useMemo, useState, createContext } from 'react';

export const VideoUploadContext = createContext({});

export const useVideoUploadContext = () => {
  const [state, dispatch] = useContext(VideoUploadContext);
  const layer = useMemo(() => [state, dispatch], [state, dispatch]);
  return layer;
};

export function VideoUploadContextList({ children }) {
  const [mediaUploading, setMedia] = useState(null);
  const layer = useMemo(() => [mediaUploading, setMedia], [mediaUploading, setMedia]);
  return <VideoUploadContext.Provider value={layer}>{children}</VideoUploadContext.Provider>;
}
