import React, { useMemo, useEffect, createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { ax } from '@/lib/api';
import { checkIfIdExist } from '@/lib/helper';
import { useIsVideoGuestBookCelebrationTheme, usePrintableEntities } from '@/hooks/celebration';

export const PDFProcessorContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.loading };
    case 'setFrontMedia':
      return { ...state, frontMedia: action.attributes };
    case 'setBackMedia':
      return { ...state, backMedia: action.attributes };
    default:
      return state;
  }
};

function PDFProcessor({ children }) {
  const {
    query: { id },
  } = useRouter();
  const isFromVGB = useIsVideoGuestBookCelebrationTheme(id);

  useSWR(
    isFromVGB && checkIfIdExist(id)
      ? `/v3/celebrations/${id}/celebration_printable_templates`
      : null
  );
  useSWR(isFromVGB && checkIfIdExist(id) ? `/v3/celebrations/${id}/celebration_printables` : null);
  const [frontPrintable, backPrintable] = usePrintableEntities();
  const [pdfProcessorState, dispatch] = useReducer(reducer, {
    loading: false,
    frontMedia: null,
    backMedia: null,
  });

  function setLoading(loading) {
    dispatch({ type: 'setLoading', loading });
  }

  function setMediaId(attributes, page) {
    dispatch({ type: page === 'front' ? 'setFrontMedia' : 'setBackMedia', attributes });
  }
  useEffect(() => {
    if (frontPrintable && isFromVGB) {
      setMediaId(
        { mediaId: frontPrintable?.['pdf-thumbnail']?.id, pdfId: frontPrintable?.pdf?.id },
        frontPrintable?.page
      );
    } else setMediaId(null, 'front');
  }, [frontPrintable, isFromVGB]);

  useEffect(() => {
    if (backPrintable && isFromVGB) {
      setMediaId(
        { mediaId: backPrintable?.['pdf-thumbnail']?.id, pdfId: backPrintable?.pdf?.id },
        backPrintable?.page
      );
    } else setMediaId(null, 'back');
  }, [backPrintable, isFromVGB]);

  async function generatePDF() {
    setLoading(true);
    try {
      const { data: newData, status } = await ax().get(
        `/v3/celebrations/${id}/celebration_printables/${frontPrintable.id}.pdf`
      );
      if (status === 201 || status === 200) {
        newData.data.forEach(({ attributes, relationships }) => {
          setMediaId(
            {
              mediaId: relationships?.['pdf-thumbnail']?.data?.id,
              pdfId: relationships?.pdf?.data?.id,
            },
            attributes?.page
          );
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if ((frontPrintable || backPrintable) && id && isFromVGB) {
      generatePDF();
    }
  }, [id, isFromVGB]);

  const pdfProcessorContextValue = useMemo(
    () => [pdfProcessorState, generatePDF, dispatch],
    [pdfProcessorState, generatePDF, dispatch]
  );

  return (
    <PDFProcessorContext.Provider value={pdfProcessorContextValue}>
      {children}
    </PDFProcessorContext.Provider>
  );
}
PDFProcessor.propTypes = { children: PropTypes.node.isRequired };

export const usePDFProcessor = () => {
  const [state, generatePDF] = useContext(PDFProcessorContext);
  return useMemo(() => ({ ...state, generatePDF }), [state, generatePDF]);
};

export default PDFProcessor;
