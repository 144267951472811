import React, { useEffect, useState, createContext, useCallback, useContext } from 'react';
import { v4 } from 'uuid';
import { Flex, Text } from 'rebass';
import { motion } from 'framer-motion';

const ConfirmationOverlayContext = createContext({});

function Overlay({ icon, message, delay = 0.7, duration = 2 }) {
  return (
    <Flex
      width={icon && '100vw'}
      height={icon && '100vh'}
      alignItems={icon ? 'center' : 'flex-end'}
      justifyContent="center"
      sx={{
        position: 'fixed',
        zIndex: 10000,
        top: icon && 0,
        left: icon ? 0 : 'calc(50% - 127px)',
        bottom: !icon && 0,
      }}
    >
      <motion.div
        initial={{ opacity: 1, y: 0 }}
        animate={{ opacity: 0, y: -10 }}
        transition={{
          delay,
          duration,
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          bg={icon ? 'rgba(0,0,0,.7)' : 'black'}
          p={icon ? 7 : 3}
          sx={{ borderRadius: 'large', boxShadow: 'large' }}
          mb={!icon && '30px'}
        >
          {icon && <Text as={icon} size={48} color="white" />}
          <Text color="white" pt={icon ? 4 : 0}>
            {message}
          </Text>
        </Flex>
      </motion.div>
    </Flex>
  );
}

function ConfirmationOverlay({ children }) {
  const [{ icon, message, id, delay, duration }, setOverlay] = useState({});

  const createOverlay = useCallback(
    (props) => {
      const newId = v4();
      setOverlay({ id: newId, ...props });
    },
    [setOverlay]
  );

  let timeOut = 3000;
  if (icon) timeOut = 1052;

  useEffect(() => {
    if (id) setTimeout(() => setOverlay({}), timeOut);
  }, [setOverlay, id]);

  return (
    <ConfirmationOverlayContext.Provider value={createOverlay}>
      {children}
      {id && <Overlay icon={icon} message={message} key={id} delay={delay} duration={duration} />}
    </ConfirmationOverlayContext.Provider>
  );
}

export const useCreateConfirmation = () => useContext(ConfirmationOverlayContext);

export default ConfirmationOverlay;
