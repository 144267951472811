import theme from '@rebass/preset';
import { DM_Sans as DMSans } from 'next/font/google';
import { viewChangePixels } from './constants';

const dmSans = DMSans({
  weight: ['400', '500', '700', '800', '900'],
  subsets: ['latin'],
});
const isFromMobile =
  typeof window !== 'undefined' && window?.globalThis?.screen?.width < viewChangePixels;

const greys = [
  '#FFFFFF',
  '#EDF2F7',
  '#E2E8F0',
  '#CBD5E0',
  '#A0AEC0',
  '#4A5568',
  '#4A5568',
  '#2D3748',
  '#1A202C',
  '#272929',
  '#47484F',
  '#505050',
];
const lightGreys = ['#EBEBF3', '#C2C2D1', '#E9E9E9', '#F7F7F7'];

const purples = [
  '#FDFDFD',
  '#E4E5FC',
  '#C1C3F5',
  '#8B90E9',
  '#6269F0',
  '#575BAD',
  '#444897',
  '#3B3F85',
  '#222333',
  '#6369EF',
  '#342E57',
];

const pinks = [
  '#FEFEFE',
  '#FEF5F8',
  '#FCCFDE',
  '#FF94B1',
  '#ED2365',
  '#BB3763',
  '#763249',
  '#643545',
  '#3D252D',
  '#cc3788',
];

const colors = {
  primary: purples[7],
  background: purples[0],
  border: greys[2],
  body: purples[8],
  lightBody: greys[6],
  success: '#004D01',
  successBg: '#DCF5E3',
  warning: '#f19953',
  error: 'rgb(237, 106, 94)',
  white: 'white',
  greys,
  lightGreys,
  purples,
  pinks,
  black: '#16161D',
  mobileEditingSuiteScreen: '#E5E5E5',
  mobileSettingsScreen: '#F8F8FA',
  special: 'linear-gradient(90deg, #3866F4 0%, #7B33FB 48.96%, #BD00FF 100%)',
  lightBlue: '#F3F6FD',
};

const radii = {
  small: 4,
  medium: 8,
  large: 12,
  xLarge: 20,
  40: '40px',
  60: '60px',
};

const fontSizes = [14, 16, 18, 20, 24, 32, 40];

export default {
  ...theme,
  fontSizes,
  colors: {
    ...theme.colors,
    ...colors,
  },
  fonts: {
    ...theme.fonts,
    heading: dmSans.style.fontFamily,
    body: dmSans.style.fontFamily,
  },
  shadows: {
    small: '0px 3px 6px 0 rgba(0, 0, 0, 0.05)',
    medium: '0 8px 16px 0 rgba(0, 0, 0, 0.08)',
    large: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
  },
  fontWeights: {
    ...theme.fontWeights,
    body: 400,
    heading: 500,
    bold: 700,
  },
  radii,
  buttons: {
    ...theme.buttons,
    primary: {
      ...theme.buttons.primary,
      fontFamily: dmSans.style.fontFamily,
      bg: colors.purples[4],
      fontWeight: 400,
      cursor: 'pointer',
      borderRadius: 'medium',
      transition: '176ms ease',
      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },
    special: {
      variant: 'buttons.primary',
      background: colors.special,
      boxShadow: '0px 10px 22px 4px rgba(99, 105, 239, 0.2)',
      fontFamily: dmSans.style.fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '120%',
      // textTransform: 'uppercase',
    },
    outline: {
      variant: 'buttons.primary',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: colors.purples[4],
      fontWeight: 600,
      background: 'transparent',
      color: colors.purples[4],
    },
    'muted-outline': {
      // bg: 'white',
      variant: 'buttons.outline',
      borderColor: colors.greys[2],
      color: colors.purples[7],
      outline: 'transparent',
      background: 'white',
    },
    'destructive-outline': {
      variant: 'buttons.outline',
      borderColor: colors.error,
      fontWeight: 500,
      color: colors.error,
      width: '100%',
      mx: 1,
    },
    control: {
      variant: 'buttons.primary',
      backgroundColor: colors.greys[3],
      color: colors.greys[7],
    },
    muted: {
      variant: 'buttons.primary',
      backgroundColor: colors.border,
      color: colors.lightBody,
    },
    destructive: {
      variant: 'buttons.primary',
      background: colors.error,
    },
    transparent: {
      outline: 'none',
      background: 'transparent',
      color: 'inherit',
      p: 0,
      fontFamily: 'body',
    },
    link: {
      variant: 'buttons.transparent',
      color: colors.primary,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  text: {
    headings: {
      h1: {
        color: pinks[4],
        fontSize: isFromMobile ? 4 : 6,
        fontWeight: 'bold',
      },
      h2: {
        color: purples[7],
        fontWeight: '600px !important',
        fontSize: isFromMobile ? '24px !important' : '32px !important',
      },
      h3: { color: purples[7], fontWeight: 600, fontSize: isFromMobile ? 20 : 4 },
      h4: { color: purples[7], fontWeight: 600, fontSize: isFromMobile ? 2 : 3 },
      h5: {
        color: purples[7],
        fontSize: 2,
        fontWeight: 500,
      },
    },
  },
  forms: {
    label: {
      color: purples[7],
      fontWeight: 600,
      fontSize: fontSizes[1],
    },
    input: {
      fontFamily: dmSans.style.fontFamily,
      color: purples[8],
      bg: colors.white,
      borderColor: colors.border,
      borderRadius: radii.small,
      fontSize: 1,
      p: 2,
    },
    select: {
      variant: 'forms.input',
    },
  },
  space: [0, 8, 16, 24, 32, 48, 64, 72, 128, 256],
  lhsButton: {
    width: 'max-content',
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '150%',
    fontStyle: 'normal',
    transform: 'skewX(-13deg)',
    bg: '#3D4138',
    display: 'block',
    m: 'auto',
  },
  styles: {
    root: {
      fontFamily: dmSans.style.fontFamily,
      letterSpacing: '-0.022em',
    },
  },
};
