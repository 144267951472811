import Axios from 'axios';
import store from 'store';

export const appOrigin = process.env.NEXT_PUBLIC_APP_ORIGIN;

export const basicHeaders = {
  accept: 'application/json',
  'X-LIFETALES-CLIENT': 'web-celebrate-client',
  'Content-Type': 'application/json',
};

export const ax = ({
  authenticated = true,
  baseURL = process.env.NEXT_PUBLIC_API_BASE,
  updatedToken,
  versionNumber,
} = {}) => {
  const token = updatedToken || store.get('token');
  const Authorization = token && authenticated ? `Token ${token}` : null;
  const headers = { ...basicHeaders };
  if (store.get('path')) headers['X-CELEBRATE-REFERER'] = store.get('path');
  if (Authorization) {
    headers.Authorization = Authorization;
  }
  if (versionNumber) headers['X-CELEBRATE-API-VERSION'] = versionNumber;

  return Axios.create({
    baseURL,
    headers,
  });
};

export const post = (key, attributes, config) => {
  const body = { data: { attributes } };

  return ax().post(key, body, config);
};

export const fetcher = (key, token) =>
  token ? ax({ updatedToken: token }).get(key) : ax().get(key);
export const publicFetcher = (key) => ax({ authenticated: false }).get(key);

export async function getCouponDetails(param, type) {
  const { data, status } = await ax().get(`/v3/public/discounts/${param}`);
  if (status === 200 || status === 201) {
    const {
      data: { attributes },
    } = data;
    return {
      title: `${attributes[`coupon-valid`] ? 'Save' : ''} ${attributes[`coupon-title${type}`]}`,
      message: attributes[`coupon-details${type}`],
      footer: attributes[`coupon-footer${type}`] ? `*${attributes[`coupon-footer${type}`]}` : '',
      isValid: attributes[`coupon-valid`],
    };
  }
  return null;
}

export async function postEventDetails(eventName, attributes) {
  await ax().post(`/v3/public/tracking_events?event_topic_name=${eventName}`, {
    data: { ...attributes },
  });
}

export async function postPrivateEventDetails(eventName, attributes) {
  await ax().post(`/v2/me/tracking_events?event_topic_name=${eventName}`, {
    data: { attributes },
  });
}

export async function sendErrorToBackend(id, message, lockVersion = 0) {
  await ax().patch(`/v2/media/${id}`, {
    data: {
      attributes: {
        'error-message-cache': message,
        'lock-version': lockVersion,
      },
    },
  });
}
